import { styled } from 'styled-components';

export const ProductPriceContainer = styled.div`
    margin: 8px 0 0;
`;

export const ProductPriceDiscount = styled.span`
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 12px;
    color: ${({ theme }) => theme.color.secondary};
    padding: 0 4px;
`;

export const ProductPriceTitle = styled.p`
    color: ${({ theme }) => theme.color.neutral500};
    font-size: ${({ theme }) => theme.fontSize.xs};

    & > ${ProductPriceDiscount} {
        margin-left: 8px;
    }
`;

export const ProductPriceValue = styled.p`
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 500;
    margin-top: 5px;
`;

export const RentRange = styled.span`
    font-size: 12px;
    font-weight: 400;
    color: #818c99;
`;
