import React from 'react';

import { SvgIcon } from '../../types/types';

export const Basket2Icon: SvgIcon = ({ width = 25, height = 24 }): JSX.Element => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.75 9C13.3 9 13.75 8.55 13.75 8V6H15.75C16.3 6 16.75 5.55 16.75 5C16.75 4.45 16.3 4 15.75 4H13.75V2C13.75 1.45 13.3 1 12.75 1C12.2 1 11.75 1.45 11.75 2V4H9.75C9.2 4 8.75 4.45 8.75 5C8.75 5.55 9.2 6 9.75 6H11.75V8C11.75 8.55 12.2 9 12.75 9ZM7.75 18C6.65 18 5.76 18.9 5.76 20C5.76 21.1 6.65 22 7.75 22C8.85 22 9.75 21.1 9.75 20C9.75 18.9 8.85 18 7.75 18ZM17.75 18C16.65 18 15.76 18.9 15.76 20C15.76 21.1 16.65 22 17.75 22C18.85 22 19.75 21.1 19.75 20C19.75 18.9 18.85 18 17.75 18ZM8.85 13H16.3C17.05 13 17.71 12.59 18.05 11.97L21.43 5.84C21.7 5.36 21.52 4.75 21.04 4.48C20.56 4.22 19.95 4.39 19.69 4.87L16.3 11H9.28L5.29 2.57C5.13 2.22 4.77 2 4.39 2H2.75C2.2 2 1.75 2.45 1.75 3C1.75 3.55 2.2 4 2.75 4H3.75L7.35 11.59L6 14.03C5.27 15.37 6.23 17 7.75 17H18.75C19.3 17 19.75 16.55 19.75 16C19.75 15.45 19.3 15 18.75 15H7.75L8.85 13Z"
                fill="#00D9DE"
            />
        </svg>
    );
};
