import React, { useEffect, useState } from 'react';

import { EMetricEvents } from '@/constants/settings';
import { STORAGE_KEYS } from '@/constants/storage-keys';
import { useAppDispatch } from '@/store/hooks';
import { changeStatusInFavorite } from '@/store/reducers/basket/slice';
import { addProductFavorite, deleteProductFavorite } from '@/store/reducers/favorite/slice';
import { showModal } from '@/store/reducers/modal/slice';
import { TRentProduct } from '@/types/products';
import { LocalStore } from '@/utils/local-store';

import { useMetric } from '@/shared/hooks/use-metric';

import { FavoriteMiniature } from '../../favorite-basket-miniature/favorite-miniature';

type TUseActionsProductsProps = {
    product: TRentProduct;
};

type TReturnedActionsProducts = {
    handleAddProductToFavorite: () => void;
    favoriteProducts: number[];
};

export const useActionsProducts = ({ product }: TUseActionsProductsProps): TReturnedActionsProducts => {
    const dispatch = useAppDispatch();

    const { id } = product;

    const { postMetric } = useMetric();

    const [favoriteProducts, setFavoriteProducts] = useState<number[]>([]);

    const changeFavoriteStatus = (array: number[]) => {
        setFavoriteProducts(array);

        LocalStore.set(STORAGE_KEYS.favoriteProducts, array);
    };

    const addingFavoriteProductToStore = () => {
        dispatch(
            showModal({
                type: 'Notification',
                content: <FavoriteMiniature />,
            }),
        );

        dispatch(changeStatusInFavorite({ product }));

        dispatch(
            addProductFavorite({
                product,
            }),
        );
    };

    const handleDeleteFavoriteProductInStore = () => {
        dispatch(deleteProductFavorite(product));
    };

    const handleAddProductToFavorite = () => {
        const favoriteProductsItems: number[] = LocalStore.get(STORAGE_KEYS.favoriteProducts);

        if (favoriteProductsItems) {
            if (!favoriteProductsItems?.includes(id)) {
                postMetric(EMetricEvents.ADDED_TO_FAVOURITES);

                changeFavoriteStatus(Array.from(new Set([...favoriteProductsItems, id])));

                addingFavoriteProductToStore();
            } else {
                postMetric(EMetricEvents.REMOVED_FROM_FAVOURITES);

                changeFavoriteStatus(favoriteProductsItems.filter((idProduct) => idProduct !== id));

                handleDeleteFavoriteProductInStore();
            }
        } else {
            changeFavoriteStatus([id]);

            addingFavoriteProductToStore();
        }
    };

    useEffect(() => {
        setFavoriteProducts(LocalStore.get(STORAGE_KEYS.favoriteProducts));
    }, []);

    return {
        handleAddProductToFavorite,
        favoriteProducts,
    };
};
