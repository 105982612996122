import { styled } from 'styled-components';

import { Wrapper as StyledProductCard } from '@/components/elements/product-card/styled';

export const StyledPopularProducts = styled.div`
    & > ${StyledProductCard} {
        margin-bottom: 12px;
    }

    & > ${StyledProductCard}:last-of-type {
        margin-bottom: 0;
    }
`;
