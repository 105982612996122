import React from 'react';

import { SvgIconInterface } from '@/types/interfaces';

type TProps = SvgIconInterface & {
    filled?: boolean;
};

export const HeartIcon: React.FC<TProps> = ({ width = 24, height = 24, filled = false }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {filled ? (
                <path
                    d="M13.3502 20.1299C12.5902 20.8199 11.4202 20.8199 10.6602 20.1199L10.5502 20.0199C5.30015 15.2699 1.87015 12.1599 2.00015 8.27992C2.06015 6.57992 2.93015 4.94992 4.34015 3.98992C6.98015 2.18992 10.2402 3.02992 12.0002 5.08992C13.7602 3.02992 17.0202 2.17992 19.6602 3.98992C21.0702 4.94992 21.9402 6.57992 22.0002 8.27992C22.1402 12.1599 18.7002 15.2699 13.4502 20.0399L13.3502 20.1299Z"
                    fill="#00D9DE"
                />
            ) : (
                <path
                    d="M19.6602 3.98992C17.0202 2.18992 13.7602 3.02992 12.0002 5.08992C10.2402 3.02992 6.98021 2.17992 4.34021 3.98992C2.94021 4.94992 2.06021 6.56992 2.00021 8.27992C1.86021 12.1599 5.30021 15.2699 10.5502 20.0399L10.6502 20.1299C11.4102 20.8199 12.5802 20.8199 13.3402 20.1199L13.4502 20.0199C18.7002 15.2599 22.1302 12.1499 22.0002 8.26992C21.9402 6.56992 21.0602 4.94992 19.6602 3.98992ZM12.1002 18.5499L12.0002 18.6499L11.9002 18.5499C7.14021 14.2399 4.00021 11.3899 4.00021 8.49992C4.00021 6.49992 5.50021 4.99992 7.50021 4.99992C9.04021 4.99992 10.5402 5.98992 11.0702 7.35992H12.9402C13.4602 5.98992 14.9602 4.99992 16.5002 4.99992C18.5002 4.99992 20.0002 6.49992 20.0002 8.49992C20.0002 11.3899 16.8602 14.2399 12.1002 18.5499Z"
                    fill="#00D9DE"
                />
            )}
        </svg>
    );
};
