import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';

export const StyledPageSectionTitleContainer = styled.div`
    ${mq.gt.sm} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const StyledPageSection = styled.section`
    margin-top: 24px;

    & ${StyledPageSectionTitleContainer} {
        margin-bottom: 16px;
    }

    &.hideByMd {
        ${mq.lt.md} {
            display: none;
        }
    }

    ${mq.gt.sm} {
        margin-top: 42px;

        & ${StyledPageSectionTitleContainer} {
            margin-bottom: 24px;
        }
    }
`;
