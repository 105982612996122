import React from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { ProductPriceContainer, ProductPriceTitle, ProductPriceDiscount, ProductPriceValue, RentRange } from './styled';

export type ProductPriceProps = {
    title: string;
    suffix?: string;
    value: number | null;
    discount?: string;
};

const ProductPrice: React.FC<ProductPriceProps> = ({ title, value, discount = '', suffix }) => {
    const { t } = useTranslate();

    return (
        <ProductPriceContainer>
            <ProductPriceTitle>
                {title}
                {discount && Number(discount) > 0 && <ProductPriceDiscount>{`-${discount}%`}</ProductPriceDiscount>}
            </ProductPriceTitle>

            <ProductPriceValue>
                {suffix && <RentRange>{suffix + ' '}</RentRange>}
                {value && t('{value} ₸/день', { value })}
            </ProductPriceValue>
        </ProductPriceContainer>
    );
};

export default ProductPrice;
