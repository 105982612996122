import { styled } from 'styled-components';

import { rowYMiddle, flexColumn } from '@/components/_shared/mixins.styled';

export const StyledMiniatureContainer = styled.div`
    background-color: transparent;
`;

export const StyledMiniatureList = styled.ul`
    ${flexColumn};
`;

export const StyledMiniatureListItem = styled.li`
    ${rowYMiddle};
    height: 42px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    .name {
        font-size: ${({ theme }) => theme.fontSize.sm};
        color: #000;
        max-width: 230px;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        height: 42px;
        word-break: break-word;
        margin-left: 24px;
    }
`;
