import React from 'react';

import { SvgIcon } from '@/types/types';

export const DangerIcon: SvgIcon = ({ color = '#FFB822', width = 10 }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 10 10" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.235 9.5H8.765C9.535 9.5 10.015 8.665 9.63 8L5.865 1.495C5.48 0.829996 4.52 0.829996 4.135 1.495L0.37 8C-0.0150004 8.665 0.464999 9.5 1.235 9.5ZM5 6C4.725 6 4.5 5.775 4.5 5.5V4.5C4.5 4.225 4.725 4 5 4C5.275 4 5.5 4.225 5.5 4.5V5.5C5.5 5.775 5.275 6 5 6ZM5.5 8H4.5V7H5.5V8Z" />
        </svg>
    );
};
