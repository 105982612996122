import React from 'react';

import { TRentProduct } from '@/types/products';

import { ProductsSlider } from '../products-slider';

import { StyledPopularProducts } from './styled';

type Props = {
    products: TRentProduct[];
    idSlider?: string;
};

export const PopularProducts: React.FC<Props> = ({ products, idSlider = '' }) => {
    return (
        <StyledPopularProducts>
            <ProductsSlider id={idSlider} products={products} />
        </StyledPopularProducts>
    );
};
