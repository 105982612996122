import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { ProductPriceValue } from '@/components/elements/product-card/product-price/styled';
import { StyledProductCardFullImageCss, ProductImageContainer } from '@/components/elements/product-card/styled';

export const StyledProductsSlider = styled.section`
    margin-left: -12px;
    margin-right: -12px;

    & .swiper-slide {
        width: max(23.43%, 233px);
        margin-left: max(2.08%, 12px);

        &:last-of-type {
            margin-right: 12px;
        }

        ${ProductImageContainer} {
            ${StyledProductCardFullImageCss}
        }

        ${ProductPriceValue} {
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }

    ${mq.gt.sm} {
        margin-left: 0;
        margin-right: 0;

        & .swiper-slide {
            margin-left: 0;
            margin-right: max(2.08%, 12px);

            &:first-of-type {
                margin-left: 0;
                margin-left: 16px;
            }

            &:last-of-type {
                margin-right: 16px;
                margin-right: 0;
            }

            ${ProductPriceValue} {
                font-size: ${({ theme }) => theme.fontSize.md};
            }
        }
    }
`;

export const StyledProductSliderContainer = styled.div`
    position: relative;
`;

export const StyledProductSliderNavButton = styled.button`
    display: none;
    position: absolute;
    background-color: ${({ theme }) => theme.color.secondary};
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 12px rgb(0 0 0 / 12%);
    cursor: pointer;
    width: 42px;
    height: 42px;
    top: 50%;
    margin-top: -21px;
    z-index: 10;

    &.swiper-button-disabled {
        display: none;
    }

    ${mq.gt.sm} {
        display: initial;
    }
`;

export const StyledProductSliderPrevNavButton = styled(StyledProductSliderNavButton)`
    left: -21px;

    & > svg {
        transform: rotate(180deg);
    }
`;

export const StyledProductSliderNextNavButton = styled(StyledProductSliderNavButton)`
    right: -21px;
`;
