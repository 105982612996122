import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    background: ${({ theme }) => theme.background.mainBackground};
    padding: 8px 30px 8px 8px;
    gap: 6px;
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const Cost = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
`;

export const Title = styled.h5`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ theme }) => theme.color.dark};
    margin-bottom: 8px;
`;

export const Date = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    color: ${({ theme }) => theme.color.dark};
`;
