import Image from 'next/image';
import React from 'react';

import { IMAGE_ALT_CUT_LENGTH, DEFAULT_IMAGE_SRC } from '@/constants/settings';
import { useAppSelector } from '@/store/hooks';
import { cutString } from '@/utils/string';

import { useTranslate } from '@/shared/hooks/use-translate';

import { DropdownModalContainer } from '../UI/dropdown-modal';

import { StyledMiniatureList, StyledMiniatureListItem, StyledMiniatureContainer } from './styled';

export const BasketMiniature: React.FC = () => {
    const { t } = useTranslate();

    const { itemsBasket } = useAppSelector((state) => state.basket);

    return (
        <StyledMiniatureContainer>
            <DropdownModalContainer textBtn={t('В корзину')} title="Корзина" href="/basket" withBg={false}>
                <StyledMiniatureList>
                    {itemsBasket.map((el, i) => (
                        <StyledMiniatureListItem key={i}>
                            <Image
                                src={el.photos?.[0]?.image ?? DEFAULT_IMAGE_SRC}
                                width={42}
                                height={42}
                                alt={cutString(el.name, IMAGE_ALT_CUT_LENGTH)}
                            />
                            <span className="name"> {el.name} </span>
                        </StyledMiniatureListItem>
                    ))}
                </StyledMiniatureList>
            </DropdownModalContainer>
        </StyledMiniatureContainer>
    );
};
