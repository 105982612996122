import { SvgIcon } from '@/types/types';

export const LibraryAddCheckIcon: SvgIcon = ({ color = '#00D9DE', width = 14 }) => {
    return (
        <svg width={width} viewBox="0 0 14 15" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.1602 0.833496H4.16016C3.42682 0.833496 2.82682 1.4335 2.82682 2.16683V10.1668C2.82682 10.9002 3.42682 11.5002 4.16016 11.5002H12.1602C12.8935 11.5002 13.4935 10.9002 13.4935 10.1668V2.16683C13.4935 1.4335 12.8935 0.833496 12.1602 0.833496ZM6.66682 8.3535L5.28682 6.96683C5.03349 6.70683 5.03349 6.2935 5.28682 6.0335C5.54682 5.7735 5.96682 5.7735 6.22682 6.0335L7.13349 6.94683L10.0802 3.9735C10.3402 3.7135 10.7602 3.7135 11.0202 3.9735C11.2735 4.2335 11.2735 4.64683 11.0202 4.90683L7.60016 8.3535C7.35349 8.62016 6.92682 8.62016 6.66682 8.3535ZM0.826823 3.50016C0.460156 3.50016 0.160156 3.80016 0.160156 4.16683V12.8335C0.160156 13.5668 0.760156 14.1668 1.49349 14.1668H10.1602C10.5268 14.1668 10.8268 13.8668 10.8268 13.5002C10.8268 13.1335 10.5268 12.8335 10.1602 12.8335H2.16016C1.79349 12.8335 1.49349 12.5335 1.49349 12.1668V4.16683C1.49349 3.80016 1.19349 3.50016 0.826823 3.50016Z"
                fill="#00D9DE"
            />
        </svg>
    );
};
