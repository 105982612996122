import { FC } from 'react';

import { LibraryAddCheckIcon } from '@/components/_icons/library-add-check-icon';
import { formatNumberWithSpaces } from '@/components/elements/product-card/buyout-block/utils/format-number-with-spaces';

import { useTranslate } from '@/shared/hooks/use-translate';

import * as Styled from './styled';

type TProps = {
    priceForTwelveMonths: number | null;
};

export const BuyoutBlock: FC<TProps> = ({ priceForTwelveMonths }) => {
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <LibraryAddCheckIcon width={22} />
            <Styled.RightSide>
                <Styled.Title>{t('Этот товар можно арендовать с последующим выкупом')}</Styled.Title>
                <Styled.Line>
                    <Styled.Cost>{`${formatNumberWithSpaces(priceForTwelveMonths)} ₸/мес`}</Styled.Cost>
                    <Styled.Date>{t('на 12 мес')}</Styled.Date>
                </Styled.Line>
            </Styled.RightSide>
        </Styled.Wrapper>
    );
};
