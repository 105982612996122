import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { checkIsNewQuestionnaireAllowed } from '@/api/sass-credit/customers-service';
import { deleteCalculatorForm } from '@/components/modules/caclulator';
import { EMetricEvents } from '@/constants/settings';
import { STORAGE_KEYS } from '@/constants/storage-keys';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { addProductBasket, deleteProductBasket } from '@/store/reducers/basket/slice';
import { showModal } from '@/store/reducers/modal/slice';
import { TRentProduct } from '@/types/products';
import { LocalStore } from '@/utils/local-store';

import { setHistoryUrl } from '@/shared/hooks/use-history-save-url/utils';
import { useMetric } from '@/shared/hooks/use-metric';

import { BasketMiniature } from '../../favorite-basket-miniature/basket-miniature';

type TUseActionsProductsProps = {
    product: TRentProduct;
};

type TReturnedActionsProducts = {
    basketProducts: number[];
    handleAddProductToBasket: () => void;
    onReserveProduct: () => Promise<void>;
};

export const useBasketActions = ({ product }: TUseActionsProductsProps): TReturnedActionsProducts => {
    const auth = useAppSelector((state) => state.user.auth);
    const dispatch = useAppDispatch();
    const { push } = useRouter();
    const { postMetric } = useMetric();
    const { id } = product;
    const [basketProducts, setBasketProducts] = useState<number[]>([]);

    useEffect(() => {
        setBasketProducts(LocalStore.get(STORAGE_KEYS.basketProducts));
    }, []);

    const changeBasketStatus = (array: number[]) => {
        setBasketProducts(array);

        LocalStore.set(STORAGE_KEYS.basketProducts, array);
    };

    const addingBasketProductToStore = () => {
        dispatch(
            showModal({
                type: 'Notification',
                content: <BasketMiniature />,
            }),
        );

        dispatch(addProductBasket({ product }));
    };

    const handleDeleteFavoriteProductInStore = () => {
        dispatch(deleteProductBasket(product));
    };

    const handleAddProductToBasket = () => {
        const basketProductsItems: number[] = LocalStore.get(STORAGE_KEYS.basketProducts);

        if (basketProductsItems) {
            if (!basketProductsItems?.includes(id)) {
                postMetric(EMetricEvents.ADDED_TO_BASKET);

                changeBasketStatus(Array.from(new Set([id, ...basketProductsItems])));

                addingBasketProductToStore();
            } else {
                postMetric(EMetricEvents.REMOVED_FROM_BASKET);

                changeBasketStatus(basketProductsItems.filter((idProduct) => idProduct !== id));

                handleDeleteFavoriteProductInStore();

                dispatch(deleteCalculatorForm(id));
            }
        } else {
            changeBasketStatus([id]);

            addingBasketProductToStore();
        }
    };

    const onReserveProduct = async () => {
        const isInBasket = basketProducts?.includes(product.id);
        if (!isInBasket) {
            await handleAddProductToBasket();
        }
        if (!auth.authenticated) {
            setHistoryUrl('/basket');
            push('/sign-in');
            return;
        }
        const response = await checkIsNewQuestionnaireAllowed();

        if (!response.answer) {
            push('/basket');
            return;
        }

        push(`/basket/submit?id=${product.id}`);
    };

    return {
        handleAddProductToBasket,
        onReserveProduct,
        basketProducts,
    };
};
