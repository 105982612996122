import { Grid } from 'antd';
import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { Heading } from '../../../heading';
import { HeadingTypes } from '../../../heading/types';

import { StyledPageSection, StyledPageSectionTitleContainer } from './styled';

const { useBreakpoint } = Grid;

type TProps = {
    title: string;
    linkComponent?: ReactNode;
    tagTitle?: HeadingTypes;
    className?: string;
    children: ReactNode;
};

export const PageSection: React.FC<TProps> = (props) => {
    const { linkComponent, title, className, children, tagTitle = 'h2' } = props;

    const theme = useTheme();

    return (
        <StyledPageSection className={className}>
            <StyledPageSectionTitleContainer>
                <Heading as={tagTitle} size={theme.fontSize.xl} sizeMobile="lg">
                    {title}
                </Heading>
                {linkComponent}
            </StyledPageSectionTitleContainer>
            {children}
        </StyledPageSection>
    );
};

type TAdditionalProps = TProps & {
    changeBy: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    deskTitle: string;
    mobTitle: string;
};

export const PageSectionWithAnotherTitle: React.FC<TAdditionalProps> = ({
    changeBy,
    deskTitle,
    mobTitle,
    ...props
}) => {
    const screen = useBreakpoint();

    const title = screen[changeBy] ? deskTitle : mobTitle;

    return <PageSection {...props} title={title} />;
};
